import React from "react"
import "../assets/styles/global.css"
import Footer from "../components/Bar/footer"
import NavBarWrapper from "../components/Bar/NavBarWrapper"
import Sponsors from "../components/Content/Testimonials/Sponsors"
import { graphql, useStaticQuery } from "gatsby"
import { SEO_OPTIONS_OVERSIGHT } from "../const/SEOOptions"
import FullPageLayout from "../layouts/FullPageLayout"
import HeaderServices from "../components/Header/HeaderServices/HeaderServices"
import Section from "../components/Section/Section"
import { DATA_MANAGEMENT_OVERSIGHT_TOOLTIPS} from "../const/MenuTooltips"
import ContactComponent from "../components/Content/Contact/ContactComponent"
import StudyTrack from "../components/Content/StudyTrack/StudyTrack"
import DelimiterSVG, { SVGDelimiter } from "../components/Section/SVGDelimiter"

const DataManagementOversightPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    {    
      headerImage: file(relativePath: { eq: "quality-assurance.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const fullpageOptions = {
    navigationTooltips: DATA_MANAGEMENT_OVERSIGHT_TOOLTIPS,
    slidesNavigation: true
  }

  return (
    <FullPageLayout
      options={fullpageOptions}
      SEOOptions={SEO_OPTIONS_OVERSIGHT}

      background={"white"}
      location={location}
    >

      <Section isFirstSection>
        <HeaderServices
          thinH1={'Data management'}
          fatH1={`Oversight`}
          p={`Spot processes gaps, prevent extra needs, <br/>ensure study success.`}
          img={data.headerImage.childImageSharp.fluid}
          alt={`Data Management Oversight`}
        />
      </Section>

      <Section>
        <StudyTrack/>
      </Section>


      <DelimiterSVG
        shape={SVGDelimiter.Shape.Rounded}
        fillColor={"#FFF"}
        Background={`rgba(1, 9, 33, 1)`}
      />


      <Section
        GradientBackground={`radial-gradient(circle at 50% 50%,rgba(4, 19, 72, 1) 0%,rgba(1, 9, 33, 1) 12rem)`}
        autoHeight
      >
        <Sponsors
        paddingTop={"7em"}
        />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Line}
        fillColor={`#010921`}
        Background={`transparent`}
      />


      <ContactComponent
        subtitle={`Spot your Data <br/>Management Gaps`}
        CTAmessage={"SCHEDULE A CALL"}
        DisplayContactForm={true}
      />

      <Footer location={location} />
    </FullPageLayout>
  )
}

export default DataManagementOversightPage