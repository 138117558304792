import React from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"

export default function StudyTrack(WithSlide) {
  return (
    <div css={StudyTrackStyles}>
      <div id="studytrack-content-ctn" className="gutter">
        <h2>Your study, on track</h2>
        <h5>
          Our experts serve as an extension of your teams
          <br />
          We can help for a part or the whole process:
        </h5>

        <div id={"track-list"}>

          <div className="ctn">
            <h3>Compliancy</h3>
            <p>
              <bold>Provide an additional dimension of expertise </bold>
              and ongoing quality control over the main deliverables provided by the study team.
            </p>
            <p>
            <bold>Provide expert review of all applicable SOPs </bold>
              and their associated
              documents.
            </p>
            <p>
             <bold>Provide expert review of all DM documents</bold>
              : DMP, DVP,
              Reconciliation Plan, EDC design and validation.
            </p>
          </div>

          <div className="ctn">
            <h3>Delivery & Tracking</h3>
            <p>
              <bold>Ensure DM deliverables are produced successfully </bold>
              within the
              expected timelines and to a high level of quality.
            </p>
            <p>
             <bold>Provide reports</bold>
              , status updates, feedback and advice to key<br />
              stake holders on study progress.
            </p>
            <p>
            <bold>Streamline communication </bold>
              directly "data manager to data<br />
              manager" to avoid misunderstandings and bottlenecks.
            </p>
            <p>
            <bold>Represent and act on behalf of the study Sponsor </bold>
            to manage and oversee deliverables. Specifications, UAT Plan, external data harmonization and management, Data transfer plan.
            </p>

          </div>

          <div className="ctn">
            <h3>Quality</h3>
            <p>
              <bold>Perform an evaluation </bold>
              of the data cleaning, query management
              and quality control process during the course of the study.
            </p>
            <p>
              <bold>Manage the protocol or CRF </bold>
              amendments including risk analysis.
            </p>
            <p>
            <bold>Act as a Subject Matter Expert </bold>
            (SME) in supporting Business
              Excellence in the implementation of new processes, trainings,
              systems, vendor quality assessments and audits.
            </p>
            <p>
              <bold>Manage Corrective Actions </bold>
              and Preventive Actions (CAPA) plans.
            </p>
            <p>
              <bold>Perform qualification, DM, database and IT systems audits.</bold>
            </p>
          </div>

          <h2>About our experts</h2>
          <h5>
            All our consultants have more than 15 years of experience
            <br />
            in RWE or Clinical Trials.
          </h5>

        </div>
      </div>
    </div>
  )
}

const StudyTrackStyles = css`
  .title {
    text-align: center;
  }

  .ctn {
    padding: 2rem 3rem;
    background-color: #E8EFF9;
    display: flex;
    align-items: baseline;
    margin-bottom: 1.4rem;
    border-radius: 0.38rem;
    max-width: 700px;
    flex-direction: column;
  }

  .ctn > p {
    margin: 20px 0 0 0;
  }

  #studytrack-content-ctn h3 {
    color: #010920;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 4px solid #AEBBCE;
    width: auto;
    font-size:24px;
  }

  #studytrack-content-ctn h2 {
    color: #000A1E;
    margin-top: 4rem;
  }

  #studytrack-content-ctn h5 {
    font-weight: 400;
    color: #010920;
  }

  #track-list {
    max-width: 59.53rem;
    margin: auto;
  }

  #track-list hr {
    background-color: #AEBBCE;
    position: relative;
    height: 0.14rem;
    right: 14.5rem;
    bottom: 1rem;
    width: 9.25rem;
  }

  bold {
    font-weight: 500;
  }


`