import Img from "gatsby-image"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { css } from "@emotion/react"

export default function Sponsors({
                                   h2,
                                   isAllRefDisplayed = true,
                                   paddingTop = 0
                                 }) {
  const data = useStaticQuery(graphql`
    {
      Bayer: file(relativePath: { eq: "sponsors/Bayer.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      } 
      Certara: file(relativePath: { eq: "sponsors/Certara.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      }   
      Chimerix: file(relativePath: { eq: "sponsors/Chimerix.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      } 
      Hopitaux_Paris: file(relativePath: { eq: "sponsors/Hopitaux_paris.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      }    
      Inserm: file(relativePath: { eq: "sponsors/Inserm.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      }  
      Kantar: file(relativePath: { eq: "sponsors/kantar.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      }      
      medecins_direct: file(relativePath: { eq: "sponsors/medecins_direct.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      }     
      Merck: file(relativePath: { eq: "sponsors/Merck.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      }   
      Novartis: file(relativePath: { eq: "sponsors/Novartis.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      }   
      Samu_social: file(relativePath: { eq: "sponsors/samusocial.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      }  
      Sanofi: file(relativePath: { eq: "sponsors/Sanofi.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      }  
      Servier: file(relativePath: { eq: "sponsors/Servier.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      }  
      Takeda: file(relativePath: { eq: "sponsors/takeda.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }      
      }  
    }
  `)

  return (<>
      <div css={SponsorsStyles} style={{
        margin: "0 auto 8em auto",
        paddingTop: paddingTop,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "82%"
      }}>
        {h2 !== undefined &&
        <h2>{h2}</h2>
        }
        <div className={"row first"}>
          <div className={"ctn"}>
            <Img
              fluid={data.Novartis.childImageSharp.fluid}
              alt={"logo Novartis"}
            />
          </div>

          <div className={"ctn"}>
            <Img
              fluid={data.Sanofi.childImageSharp.fluid}
              alt={"logo Sanofi"}
            />
          </div>

          <div className={"ctn"}>
            <Img
              fluid={data.Chimerix.childImageSharp.fluid}
              alt={"logo Chimerix"}
            />
          </div>

          <div className={"ctn"}>
            <Img
              fluid={data.Merck.childImageSharp.fluid}
              alt={"logo Merck"}
            />
          </div>
        </div>

        <div className={"row second"}>
          <div className={"ctn"}>
            <Img
              fluid={data.Servier.childImageSharp.fluid}
              alt={"logo Servier"}
            />
          </div>
          <div className={"ctn"}>
            <Img
              fluid={data.Certara.childImageSharp.fluid}
              alt={"logo Certara"}
            />
          </div>
          <div className={"ctn"}>
            <Img
              fluid={data.Kantar.childImageSharp.fluid}
              alt={"logo Kantar"}
            />
          </div>
          <div className={"Bayer ctn"}>
            <Img
              fluid={data.Bayer.childImageSharp.fluid}
              alt={"logo Bayer"}
            />
          </div>
        </div>

        <div className={"row third"}>
          <div className={"ctn"}>
            <Img
              fluid={data.Takeda.childImageSharp.fluid}
              alt={"logo takeda"}
            />
          </div>
          <div className={"ctn"}>
            <Img
              fluid={data.medecins_direct.childImageSharp.fluid}
              alt={"logo médecin direct"}
            />
          </div>
          <div className={"ctn"}>
            <Img
              fluid={data.Hopitaux_Paris.childImageSharp.fluid}
              alt={"logo hôpitaux de Paris assistance publique"}
            />
          </div>
        </div>

        <div className={"row fourth"}>
          <div className={"ctn"}>
            <Img
              fluid={data.Samu_social.childImageSharp.fluid}
              alt={"Logo Samu social de Paris"}
            />
          </div>
          <div className={"ctn"}>
            <Img
              fluid={data.Inserm.childImageSharp.fluid}
              alt={"Logo Inserm"}
            />
          </div>
        </div>
        {isAllRefDisplayed && <>
          <Link className="cta-link" to="/references/">
            all references
          </Link>

          <hr />
        </>
        }
      </div>
    </>
  )
}

const SponsorsStyles = css`
  h2 {
    text-transform: none !important;
    margin: 4vh 0 3vh 0;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .first {
    width: 100%;
  }

  .second {
    width: 100%;
    margin-left: 4em;
  }

  .third {
    width: 90%;
  }

  .fourth {
    width: 90%;
  }

  .ctn {
    width: 23%;
    align-items: center;
  }

  .row div .gatsby-image-wrapper {
    max-height: 75px;
    width: auto;
    margin: 1em;
  }

  .Bayer > .gatsby-image-wrapper {
    width: 35% !important;
  }

  .Bayer {
    width: 20% !important;
  }

  @media screen and (max-width: 767px) {
    .third {
      width: 100%;
    }

    .fourth .ctn {
      width: 35%;
    }

    .third .ctn {
      width: 27%;
    }

    .second .ctn {
      width: 25%;
    }

    .ctn {
      width: 35%;
      align-items: center;
    }

  }


  @media screen and (max-width: 500px) {
    .ctn {
      width: 40%;
      align-items: center;
    }
  }

`